import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Link } from 'gatsby';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <section className="bg-ss-blue-900 text-white text-center lg:text-left relative pt-8">
            <div className="container mx-auto px-8 relative lg:flex">
                <h1 className="font-heading text-2xl lg:text-2xl mb-10 leading-tight">
                    Page not found
                </h1>
            </div>
        </section>
        <section className="container mx-auto px-8 mt-16 md:mt-16 text-center lg:text-left lg:flex">
            <p>
                We can't seem to find this page. Maybe you were{' '}
                <Link to="/new-heights" className="underline">
                    looking for this?
                </Link>
            </p>
        </section>
    </Layout>
);

export default NotFoundPage;
